import { environment } from '../../environments/environment';

export const URLS = Object({

  // Dashboard
  'dashboard': `${environment.apiUrl}/dashboard`,
  // Masters
  'categoriesList': `${environment.apiUrl}/categories/list`,
  'categoriesUpdateStatus': `${environment.apiUrl}/categories/updateStatus`,
  'categoriesAdd': `${environment.apiUrl}/categories/add`,
  'categoriesUpdate': `${environment.apiUrl}/categories/update`,
  'categoryDetails': `${environment.apiUrl}/categories/details`,
  'faqCategoriesList': `${environment.apiUrl}/faqcategories/list`,
  'faqCategoriesUpdateStatus': `${environment.apiUrl}/faqcategories/updateStatus`,
  'faqCategoriesAdd': `${environment.apiUrl}/faqcategories/add`,
  'faqCategoriesUpdate': `${environment.apiUrl}/faqcategories/update`,
  'countriesList': `${environment.apiUrl}/countries/list`,
  'countriesAdd': `${environment.apiUrl}/countries/add`,
  'countriesUpdate': `${environment.apiUrl}/countries/update`,
  'countriesUpdateStatus': `${environment.apiUrl}/countries/updateStatus`,
  'contactustopicsList': `${environment.apiUrl}/contactustopics/list`,
  'contactustopicsUpdateStatus': `${environment.apiUrl}/contactustopics/updateStatus`,
  'contactustopicsUpdate': `${environment.apiUrl}/contactustopics/update`,
  'contactustopicsAdd': `${environment.apiUrl}/contactustopics/add`,
  'commisionList': `${environment.apiUrl}/commision/list`,
  'commisionAdd': `${environment.apiUrl}/commision/add`,
  'commisionUpdate': `${environment.apiUrl}/commision/update`,
  'commisionUpdateStatus': `${environment.apiUrl}/commision/updateStatus`,
  'cancellationTimeList': `${environment.apiUrl}/cancellationtime/list`,
  'cancellationTimeAdd': `${environment.apiUrl}/cancellationtime/add`,
  'cancellationTimeUpdate': `${environment.apiUrl}/cancellationtime/update`,
  'cancellationTimeUpdateStatus': `${environment.apiUrl}/cancellationtime/updateStatus`,
  'cancellationTimeOrganizersList': `${environment.apiUrl}/cancellationtime/organizerslist`,
  'cancellationTimeEventsList': `${environment.apiUrl}/cancellationtime/organizerevents`,
  // Email
  'emailList': `${environment.apiUrl}/emailtemplate/list`,
  'emailDetails': `${environment.apiUrl}/emailtemplate/details`,
  'emailAdd': `${environment.apiUrl}/emailtemplate/add`,
  'emailUpdate': `${environment.apiUrl}/emailtemplate/update`,
  'emailStatus': `${environment.apiUrl}/emailtemplate/status`,
  // CMS
  'cmsList': `${environment.apiUrl}/cms/list`,
  'cmsAdd': `${environment.apiUrl}/cms/add`,
  'cmsUpdate': `${environment.apiUrl}/cms/update`,
  'cmsDetails': `${environment.apiUrl}/cms/detail`,
  'cmsUpdateStatus': `${environment.apiUrl}/cms/updatestatus`,
  'privacyPolicy': `${environment.apiUrl}/cms/privacy-policy`,
  // User
  'userListing': `${environment.apiUrl}/users/list`,
  'userDetails': `${environment.apiUrl}/users/detail`,
  'userUpdateStatus': `${environment.apiUrl}/users/updateStatus`,
  'verifyProfile': `${environment.apiUrl}/customer/update`,
  // FAQ
  'faqdetailsList': `${environment.apiUrl}/faqs/list`,
  'faqdetailsUpdateStatus': `${environment.apiUrl}/faqs/updateStatus`,
  'faqdetailsAdd': `${environment.apiUrl}/faqs/add`,
  'faqdetailsUpdate': `${environment.apiUrl}/faqs/update`,
  'faqdetailsDetails': `${environment.apiUrl}/faqs/detail`,
  // Event
  'eventList': `${environment.apiUrl}/adminevent/list`,
  'eventDetail': `${environment.apiUrl}/adminevent/detail`,
  'visiEvent': `${environment.apiUrl}/adminevent/visible`,
  'featureEvent': `${environment.apiUrl}/adminevent/feature`,
  'publicEvent': `${environment.apiUrl}/adminevent/public`,
  'privateEvent': `${environment.apiUrl}/adminevent/private`,
  'addEvent': `${environment.apiUrl}/adminevent/add-event`,
  'updateEvent': `${environment.apiUrl}/adminevent/update-event`,
  'eventTypeList': `${environment.apiUrl}/adminevent/eventType`,
  'categoryDropdownData': `${environment.apiUrl}/adminevent/categoryDropdownData`,
  'creatorDropdownData': `${environment.apiUrl}/adminevent/creatorDropdownData`,
  'getfollowersandfollowing': `${environment.apiUrl}/adminevent/getfollowersandfollowing`,
  'deleteMedia': `${environment.apiUrl}/adminevent/delete-event`,
  'eventNotification': `${environment.apiUrl}/adminevent/event-notification`,
  'emailMsgSend': `${environment.apiUrl}/adminevent/email-report-send`,
  'eventTitle': `${environment.apiUrl}/adminevent/eventTitle`,
  'storeDropdownList': `${environment.apiUrl}/adminevent/store_list_dropdown`,
  'getTicketTier':  `${environment.apiUrl}/adminevent/ticket-tier`,
  'addSeatingArrangement': `${environment.apiUrl}/adminevent/add-seating-arrangement`,
  'templateName': `${environment.apiUrl}/adminevent/template-name-dropdown`,
  'addSeatingIdEvent': `${environment.apiUrl}/adminevent/seating-template-add-event`,
  'updateTicketCount': `${environment.apiUrl}/adminevent/update-ticket-count`,
  //Trending
  'getTrend': `${environment.apiUrl}/adminevent/trending`,
  'updateTrend': `${environment.apiUrl}/adminevent/trending`,
  // Features
  'featureList': `${environment.apiUrl}/adminevent/feature/list`,
  'approveFeature': `${environment.apiUrl}/adminevent/feature/approve`,
  // Transaction
  'transactionList': `${environment.apiUrl}/transaction/list`,
  'transactionDetail': `${environment.apiUrl}/transaction/detail`,
  // Report
  'inappropriateeventsList': `${environment.apiUrl}/inappropriateevents/list`,
  'inappropriateeventsUpdatestatus': `${environment.apiUrl}/inappropriateevents/updatestatus`,
  'emailReportData': `${environment.apiUrl}/inappropriateevents/emailReport`,
  // Contact Us
  'contactusList': `${environment.apiUrl}/contactus/list`,
  'contactusUpdateStatus': `${environment.apiUrl}/contactus/updatestatus`,
  'contactUsDetails': `${environment.apiUrl}/contactus/view`,
  // Booking Cancellation Requests
  'bookingCancellationRequestList': `${environment.apiUrl}/booking-cancellation-requests/list`,
  'bookingCancellationRequestUpdateStatus': `${environment.apiUrl}/booking-cancellation-requests/updatestatus`,
  // Reports
  'featuredEventPaymentsReport': `${environment.apiUrl}/reports/featured-payments`,
  'vendorEarnings': `${environment.apiUrl}/reports/vendor-earnings`,
  'eventBookingsReport': `${environment.apiUrl}/reports/featured-payments`,
  'paymentReport': `${environment.apiUrl}/reports/payment-report`,
  'topAttendedCustomer' : `${environment.apiUrl}/reports/top-attended-customer`,
  'categoriesReport': `${environment.apiUrl}/reports/categories-report`,
  'eventReport': `${environment.apiUrl}/reports/event-report`,
  // Common
  'login': `${environment.apiUrl}/user/login`,
  'logout': `${environment.apiUrl}/user/logout`,
  'menuItems': `${environment.apiUrl}/user/menuitems`,
  'notification': `${environment.apiUrl}/notifications/list`,
  'notificationUpdate': `${environment.apiUrl}/notifications/update`,
  'profileDetails': `${environment.apiUrl}/adminuser/profile`,
  'updateProfile': `${environment.apiUrl}/adminuser/updateprofile`,
  'changePassword': `${environment.apiUrl}/users/changepassword`,
  'forgotPassword': `${environment.apiUrl}/user/forgetpassword`,
  'expireLink': `${environment.apiUrl}/customer/pwdlinkexpire`,
  'sendNotification': `${environment.apiUrl}/notifications/send-push`,
  'dropdownData': `${environment.apiUrl}/adminevent/dropdown-list`,
  'listingNotificationDetails': `${environment.apiUrl}/notification/view-details`,
  //setting
  'getSetting': `${environment.apiUrl}/setting`,
  'updateSetting': `${environment.apiUrl}/setting/update`,
  //Pending Verification
  'getPendingVerification': `${environment.apiUrl}/customer/user-verification-list`,
  'userVerificationData': `${environment.apiUrl}/customer/user-verification-detail`,
  'userVerificationStatusUpdate': `${environment.apiUrl}/customer/user-verification-status`,
  //Promotion
  'addPromotionData': `${environment.apiUrl}/promotion/promotion-add`,
  'customerDropdownData': `${environment.apiUrl}/promotion/customer-dropdown`,
  'promotionList': `${environment.apiUrl}/promotion/list`,
  'promotionData': `${environment.apiUrl}/promotion/promotion-view-details`,
  'promotionStatus': `${environment.apiUrl}/promotion/promotion-status`,
  'eventDropdownData': `${environment.apiUrl}/promotion/event-dropdown`,
  'promotionDetail': `${environment.apiUrl}/promotion/detail`,
  'updatePromotion': `${environment.apiUrl}/promotion/update-promotion`,

  //currencies
  'currenciesList': `${environment.apiUrl}/currencies/currencies-list`,
  'updateCurrencies': `${environment.apiUrl}/currencies/currencies-edit`,
  'currenciesData': `${environment.apiUrl}/currencies/currencies-get-data`,
  'refreshRatedata': `${environment.apiUrl}/currencies/currencies-refresh-rate`,
  //stores
  'addStoresData': `${environment.apiUrl}/stores/stores-add`,
  'currencyData': `${environment.apiUrl}/stores/currency-code-dropdown`,
  'storeList': `${environment.apiUrl}/stores/store-list`,
  'storeStatus': `${environment.apiUrl}/stores/store-status`,
  'storeData': `${environment.apiUrl}/stores/store-detail`,
  'updateStore': `${environment.apiUrl}/stores/store-edit`,
  //Seating Template
  'seatingTemplateList': `${environment.apiUrl}/seatingTemplate/seating-template-listing`,
  'addSeatingTemplate': `${environment.apiUrl}/seatingTemplate/seating-template-add`,
  'seatingTemplateStatus': `${environment.apiUrl}/seatingTemplate/seating-template-status`,
  'seatingTemplateData': `${environment.apiUrl}/seatingTemplate/seating-template-details`,
  'updateSeatingTemplate': `${environment.apiUrl}/seatingTemplate/seating-template-edit`,
  //event_access_requests
  'eventAccessRequests': `${environment.apiUrl}/adminevent/event_access_requests`,
  'eventAccessRequestsUpdate': `${environment.apiUrl}/adminevent/event_access_request_update`,
  'getModules': `${environment.apiUrl}/admin/get-permission-modules`,
  'addPermission': `${environment.apiUrl}/admin/add-permission`,
  'listPermission': `${environment.apiUrl}/admin/list-permission`,
  'updatePermissionStatus': `${environment.apiUrl}/admin/update-permission-status`,
  'getPermissionDetails': `${environment.apiUrl}/admin/get-permission-details`,
  'updatePermission': `${environment.apiUrl}/admin/update-permission`,
  'vendorlogin': `${environment.apiUrl}/vendor/login`,
  // Banner
  'bannerList': `${environment.apiUrl}/banner/list`,
  'bannerAdd': `${environment.apiUrl}/banner/add`,
  'bannerUpdate': `${environment.apiUrl}/banner/update`,
  'bannerUpdateStatus': `${environment.apiUrl}/banner/updateStatus`,
  'bannerDetails': `${environment.apiUrl}/banner/details`,
  //Event Invites
  'eventListing': `${environment.apiUrl}/admin/list-events`,
  'countryCodes': `${environment.apiUrl}/master/countrycodes`,
  'sendInvite': `${environment.apiUrl}/admin/send-invite`,
  'sendBulkInvite': `${environment.apiUrl}/admin/send-bulk-invite`,
  'generateSiteMap':`${environment.apiUrl}/generate-sitemap`
})

export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';
