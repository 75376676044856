import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { SettingService } from './setting.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-setting',
  providers: [SettingService],
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  ckeConfig: any;
  featuredPrice: any;
  featuredDuration: any;
  supportPhone: any;
  imageSrc: string;
  images: any[] = [];
  settingForm: FormGroup;

  public SettingList: any;
  public settingId: any;
  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    public notification: NzNotificationService,
    public settingService: SettingService
  ) { }

  ngOnInit() {
    // ckeditor toolbar config
    this.ckeConfig = {
      allowedContent: false,
      autoParagraph: false,
      // extraPlugins: 'divarea',
      // forcePasteAsPlainText: true,
      font_names: 'Arial;Times New Roman;Verdana',
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: ''
    };
    
    this.settingForm = this.fb.group({
      featuredPrice: ['', Validators.required],
      featuredDuration: ['', Validators.required],
      supportPhone: ['', [Validators.required]],
      cancellationPolicyEn: [''],
      cancellationPolicyAr: [''],
    });

    this.getSetting();
  }

  getSetting() {
    this.subscription.add(
      this.settingService.getSettingData().subscribe((res) => {
        if (res['code'] === 200) {
          this.SettingList = res['data'];
          this.settingId = this.SettingList['setting_id'];
          this.featuredPrice = this.SettingList['featured_price'];
          this.featuredDuration = this.SettingList['featured_duration'];
          this.supportPhone = this.SettingList['support_phone'];
          this.settingForm.patchValue({
            cancellationPolicyEn: this.SettingList.cancellation_policy_en,
            cancellationPolicyAr: this.SettingList.cancellation_policy_ar,
          })
        }
        else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        })
    )
  }

  get formControls() { return this.settingForm.controls; }

  submitSetting() {
    if (this.featuredPrice == null || this.featuredPrice == "") {
      this.notification.error('Error', 'Enter featured price');
      return;
    }
    if (this.featuredDuration == null || this.featuredDuration == "") {
      this.notification.error('Error', 'Enter featured duration');
      return;
    }
    if (this.supportPhone == null || this.supportPhone == "") {
      this.notification.error('Error', 'Enter Support Phone');
      return;
    }
    if (isNaN(Number(this.supportPhone)) || this.supportPhone.length < 8 || this.supportPhone.length > 12) {
      this.notification.error('Error', 'Support Phone must be a number with 8 to 12 digits');
      return;
    }

    const params = {
      setting_id: +this.settingId,
      featured_price: +this.featuredPrice,
      featured_duration: +this.featuredDuration,
      support_phone: + this.supportPhone,
      cancellation_policy_en: this.formControls.cancellationPolicyEn.value,
      cancellation_policy_ar: this.formControls.cancellationPolicyAr.value,
    }
    this.subscription.add(
      this.settingService.updateSettingData(params).subscribe((res) => {
        if (res['code'] === 200) {
          this.notification.success('Success', res['message']);
          this.getSetting();
        }
        else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        }
      )
    )
  }

  regenerateSiteMap(){
    this.subscription.add(
      this.settingService.generateSiteMap().subscribe((res) => {
        this.notification.success('Success', res['message']);
      },
        (err) => {
          console.log(err);
        }
      )
    )
  }

}






