import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { WelcomeComponent } from './_common/welcome/welcome.component'
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewNotificationComponent } from './view-notification/view-notification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './_guards';
import { Role } from './_models/role';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ManageEventReportComponent } from './manage-event-report/manage-event-report.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FeatureEventComponent } from './feature-event/feature-event.component';
import { SettingComponent } from './setting/setting.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsModule } from './contact-us/contact-us.module';
import { SeatingArrangementComponent } from './seating-arrangement/seating-arrangement.component';
import { WalletIndexComponent } from './wallet-index/wallet-index.component';
import { VendorEditProfileComponent } from './_config/vendor-edit-profile/vendor-edit-profile.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
  },
  {
    path: 'vendor-login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      breadcrumb: 'Privacy Policy'
    },
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        },
      },
      {
        path: 'notification',
        component: ViewNotificationComponent,
        data: {
          breadcrumb: 'Notification',
          title: 'Notification'
        },
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change Password'
        },
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        data: {
          title: 'Edit Profile'
        },
      },
      {
        path: 'vendor-edit-profile',
        component: VendorEditProfileComponent,
        data: {
          title: 'Edit Profile'
        },
      },
      {
        path: 'feature-event-request',
        component: FeatureEventComponent,
        data: {
          title: 'Feature Event Request'
        },
      },
      {
        path: 'manage-user',
        data: {
          breadcrumb: 'Users Listing'
        },
        loadChildren: () => import('./user/user.module').then(mod => mod.UserModule)
      },
      {
        path: 'manage-event',
        data: {
          breadcrumb: 'Events Listing'
        },
        loadChildren: () => import('./manage-event/manage-event.module').then(mod => mod.ManageEventModule)
      },
      {
        path: 'manage-event-reports',
        data: {
          title: 'Manage Inappropriate Event Report Listing'
        },
        component: ManageEventReportComponent
      },
      {
        path: 'manage-email-template',
        data: {
          breadcrumb: 'Email Template Listing'
        },
        loadChildren: () => import('./manage-email-template/manage-email-template.module').then(mod => mod.ManageEmailTemplateModule)
      },
      // {
      //   path: 'contact-us',
      //   data: {
      //     title: 'Enquiry Listing'
      //   },
      //   component: ContactUsModule
      // },
      {
        path: 'transaction-history',
        data: {
          breadcrumb: 'Transaction History Listing'
        },
        loadChildren: () => import('./transaction-history/transaction-history.module').then(mod => mod.TransactionHistoryModule)
      },
      {
        path: 'create-notification',
        data: {
          breadcrumb: 'Send Notification'
        },
        loadChildren: () => import('./notification/notification.module').then(mod => mod.NotificationModule)
      },
      {
        path: 'trending-event',
        data: {
          breadcrumb: 'Trending'
        },
        loadChildren: () => import('./master-management/trending-events/trending-events.module').then(mod => mod.TrendingEventsModule)
      },
      {
        path: 'master-management',
        loadChildren: () => import('./master-management/master-management.module').then(mod => mod.MasterManagementModule)
      },
      {
        path: 'cms',
        data: {
          breadcrumb: 'CMS Listing'
        },
        loadChildren: () => import('./cms/cms.module').then(mod => mod.CmsModule)
      },
      {
        path: 'contact-us',
        data: {
          breadcrumb: 'contact-us Listing'
        },
        loadChildren: () => import('./contact-us/contact-us.module').then(mod => mod.ContactUsModule)
      },
      {
        path: 'faq',
        data: {
          breadcrumb: 'FAQ Listing'
        },
        loadChildren: () => import('./faq/faq.module').then(mod => mod.FaqModule)
      },
      {
        path: 'banner',
        data: {
          breadcrumb: 'Banner Listing'
        },
        loadChildren: () => import('./banner/banner.module').then(mod => mod.BannerModule)
      },
      {
        path: 'event-invites',
        data: {
          breadcrumb: 'Event Invites',
        },
        loadChildren: () => import('./event-invites/event-invites.module').then(mod => mod.EventInvitesModule)
      },
      {
        path: 'booking-cancellation-requests',
        data: {
          breadcrumb: 'Booking Cancellation Requests'
        },
        loadChildren: () => import('./booking-cancellation-request/booking-cancellation-request.module')
          .then(mod => mod.BookingCancellationRequestModule),
      },
      {
        path: 'setting',
        data: {
          title: 'General Settings',
        },
        component: SettingComponent
      },
      {
        path: 'reports',
        data: {
          breadcrumb: 'Reports'
        },
        loadChildren: () => import('./reports/reports.module')
          .then(mod => mod.ReportsModule),
      },
      {
        path: 'pending-verification',
        data: {
          breadcrumb: 'Pending Verification'
        },
        loadChildren: () => import('./pending-verification/pending-verification.module').then(mod => mod.PendingVerificationModule)
      },
      {
        path: 'promotion',
        data: {
          breadcrumb: 'Promotion'
        },
        loadChildren: () => import('./promotion/promotion.module').then(mod => mod.PromotionModule)
      },
      {
        path: 'place-to-visit',
        data: {
          breadcrumb: 'Place To Visit'
        },
        loadChildren: () => import('./place-to-visit/place-to-visit.module').then(mod => mod.PlaceToVisitModule)
      },
      {
        path: 'listing-notification',
        data: {
          breadcrumb: 'Listing Notification'
        },
        loadChildren: () => import('./listing-notification/listing-notification.module').then(mod => mod.ListingNotificationModule)
      },
      {
        path: 'currencies',
        data: {
          breadcrumb: 'Currencies'
        },
        loadChildren: () => import('./currencies/currencies.module').then(mod => mod.CurrenciesModule)
      },
      {
        path: 'stores',
        data: {
          breadcrumb: 'Stores'
        },
        loadChildren: () => import('./stores/stores.module').then(mod => mod.StoresModule)
      },
      {
        path: 'permissions',
        data: {
          breadcrumb: 'Permissions'
        },
        loadChildren: () => import('./permission/permission.module').then(mod => mod.PermissionModule)
      },
      {
        path: 'seating-template',
        data: {
          breadcrumb: 'Seating Template'
        },
        loadChildren: () => import('./seating-template/seating-template.module').then(mod => mod.SeatingTemplateModule)
      },
      {
        path: 'seating-arrangement/:id',
        data: {
          title: 'Seating Arrangement',
        },
        component: SeatingArrangementComponent
      },
      {
        path: 'welcome',
        data: {
          title: 'Welcome'
        },
        component: WelcomeComponent
      },
      {
        path: 'wallet',
        data: {
          title: 'Wallet'
        },
        component: WalletIndexComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
